import React from "react"
import mmisiewicz from "../images/mmisiewicz.png"
import Sidenote from "./sidenote"

const sn1 = (
  <a href="http://adexchanger.com/ad-exchange-news/6-months-after-fraud-cleanup-appnexus-shares-effect-on-its-exchange/">
    6 Months After Fraud Cleanup, AppNexus Describes Impact On Its Exchange
  </a>
)

const sn2 = (
  <a href="http://digiday.com/agencies/appnexus-filters-65-percent-impressions-fraudulent/">
    After filtering for fraud, AppNexus transactions fell by 65 percent
  </a>
)

const sn3 = (
  <a href="https://www.wsj.com/articles/at-t-in-talks-to-acquire-appnexus-for-about-1-6-billion-1529464400">
    AT&T in Talks to Acquire AppNexus for About $1.6 Billion.
  </a>
)

const sn4 = (
  <a href="https://www.justice.gov/usao-edny/pr/two-international-cybercriminal-rings-dismantled-and-eight-defendants-indicted-causing">
    Two International Cybercriminal Rings Dismantled and Eight Defendants Indicted for Causing Tens
    of Millions of Dollars in Losses in Digital Advertising Fraud.
  </a>
)

const sn7 = (
  <a href="https://techcrunch.com/2019/10/29/yext-answers/">
    Yext Answers helps businesses provide better site search
  </a>
)
// extraCss can be injected to adjust height of sidenotes, for example `relative-l top--2-l`

export default () => {
  return (
    <>
      <div
        className="mw8"
        style={{
          margin: `0 auto`,
        }}
      >
        <div
          id="imgcontainer"
          className="bg-center fr-l order-0 pv6 center relative-l right--1-l mv3-l pb3 h5-l w5-l mw5 br4 cover"
          style={{
            backgroundImage: `url(${mmisiewicz})`,
          }}
        ></div>
        <article className="pl3-l pa3 w-70-l center">
          <h1>Data Science Leader</h1>
          <p>
            Hello! I am a New York City based leader of scientists and analysts, and I love working
            on projects where data is the heart of the problem.
          </p>
          <p>My skills on the softer side include:</p>
          <ul>
            <li>
              <b>Leadership.</b> I place great value on developing the careers of those who report
              to me, through learning, growth and mentorship.
            </li>
            <li>
              <b>Green field problem solving.</b> I do a lot of my best work when given large
              unstructured problems and a mandate to come up with solutions for them.
            </li>
            <li>
              <b>Construction of taxonomies and vocabularies.</b> I excel at understanding the world
              through these verbal systems. I do well at breaking down big problems into smaller
              ones, shaping things up, and finding out all of the contingencies and boundaries of
              challenging problems. Also a bit of pressure helps too!
            </li>
            <li>
              <b>Brainstorming</b>. Connecting parts of systems in novel ways.{" "}
            </li>
          </ul>
          <p>On the more technical side:</p>
          <ul>
            <li>
              <b>Deep neural networks in PyTorch.</b> Transformers have been a huge game changer,
              especially&nbsp;<a href="https://github.com/google-research/bert">BERT</a> (and
              related models)!{" "}
            </li>
            <li>
              <b>Unsupervised machine learning.</b> Clustering (DBSCAN, k-means)
            </li>
            <li>
              <b>Big data tools.</b> Spark, Scala, Postgres and Presto are my favorites.
            </li>
            <li>
              <b>Information retrieval algorithms.</b> Minhash and indexing!
            </li>
            <li>
              <b>Machine learning and Scientific computing.</b> Python, Pandas, Scikit-learn, numpy,
              R and so on.
            </li>
            <li>Networking and computer security</li>
            <li>General programming and linux tools (Java, Make, Git, etc.)</li>
          </ul>
          <h2>How&apos;d I get here?</h2>
          <h3>Yext</h3>
          <p>
            Currently I work at Yext, where I am leading Data Science efforts for a new product
            called Answers
            <Sidenote id="sn-7" note={sn7} />. Our goal is to provide a Google-like search
            experience for anyone&apos;s website. So there&apos;s tons of NLP work to be done! On a
            day to day basis, I&apos;m helping establish new procedures and protocols for use of
            data at Yext, mentoring, and working closely with product and engineering. In terms of
            research projects, some of them are&nbsp;{" "}
            <a href="http://www.norvig.com/spell-correct.html">some of the greatest hits</a> of
            information retreival and machine learning. But we&apos;re not just using tried and true
            methods, we are also taking advantage of the huge advances in Deep Learning for NLP,
            particularly with BERT for question answering, named entity recognition and clustering.
          </p>
          <h3>AppNexus</h3>
          <p>
            At AppNexus, I led a group of five Data Scientists whose job it was to detect and
            eliminate fraud.
            <Sidenote id="sn-1" note={sn1} /> We filtered out botnets, click bots, fraudulent "cash
            out" sites and fake news. We started in 2015 <Sidenote id="sn-2" note={sn2} />.
            Ultimately, in 2018, AppNexus was acquired by AT&T for around 1.7 billion dollars to
            become Xandr <Sidenote id="sn-3" note={sn3} />. On a day to day basis, I was in charge
            of making sure the research group was productive and reacting to needs throughout the
            company. I was also responsible for working closely with executives, product, marketing,
            legal and law enforcement. <Sidenote id="sn4" note={sn4} />
          </p>
          <h3>McGill</h3>
          <p>
            For my master&apos;s degree I worked on a co-supervised project in the laboratories of{" "}
            <a href="http://www.ladydavis.ca/en/andrealeblanc">Dr. Andréa LeBlanc</a> and{" "}
            <a href="http://www.ruthsresearch.org">Dr. Derek Ruths</a>. I&apos;m extraordinarily
            grateful for the opportunity and the scientific training I received at McGill has shaped
            me for the rest of my life.
          </p>
          <h3>Consulting</h3>
          <p>
            I&apos;ve always enjoyed novel consulting engagements with various and sundry clients
            with unique problems. Since 2005 I&apos;ve taken on various projects for a variety of
            clients in topics such as security and data analysis to detect ad fraud.
          </p>
          <h2>Hobbies and interests</h2>
          <p>
            I love cooking, exercise, design, photography, and my environment in Bushwick, Brooklyn.
            Colorful lights and lasers are my favorite and I love the diversity of NYC.
          </p>
          <h2>Work with me</h2>
          <p>
            If you think my unique mix of skills might be a good fit for a project you have, send me
            an <a href="mailto:mmisiewicz+website@gmail.com">email!</a> I&apos;m particularly
            interested in continuing to grow and develop my soft skills, and I&apos;m quite
            interested in consulting engagements as well.
          </p>
        </article>
      </div>
    </>
  )
}
